<template>
  <div class="my_content">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="全部" name="999"></el-tab-pane>
      <el-tab-pane label="已开票" name="1"></el-tab-pane>
      <el-tab-pane label="未开票" name="0"></el-tab-pane>
    </el-tabs>

    <div class="my_list" v-if="list.length != 0">
      <div v-for="item in list" :key="item.id">
        <div class="item">
          <div class="left">
            <div class="title">
              <img src="../../assets/user/invoice_icon.png" alt="" />
              <p class="font18 color33">
                {{ item.skillCategoryFirstName }}
                -
                {{ item.skillCategorySecondName }}
                -
                {{ item.skillCategoryThirdName }}
                考试
              </p>
            </div>
            <span class="font14 color99"
              >报考时间：{{ item.planStartTime }}</span
            >
            <span class="font14 color99">支付金额：{{ item.payAmount }}元</span>
          </div>
          <div class="right">
            <p class="tip color99 font14" v-if="item.invoiceStatus == 1">
              已开票，等待审核
            </p>
            <p class="tip color99 font14" v-else-if="item.invoiceStatus == 2">
              审核通过
            </p>

            <p class="tip color99 font14" v-else-if="item.invoiceStatus == 3">
              审核不通过
            </p>
            <p class="tip color99 font14" v-else-if="item.invoiceStatus == 4">
              已开票，请到邮箱查看
            </p>

            <span class="btn colorff font14" v-else @click="toOpenInvoice(item)"
              >申请开票</span
            >
          </div>
        </div>
        <div class="line"></div>
      </div>
    </div>
    <div class="empty" v-else>
      <div>
        <img src="../../assets/empty_box.png" alt="" />
        <p>暂无数据</p>
      </div>
    </div>
  </div>
</template>

<script>
import {queryPlanListByInvoice} from "@/util/api";
export default {
  data() {
    return {
      activeName: "999",
      list: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      queryPlanListByInvoice({
        status: this.activeName == 999 ? "" : this.activeName,
      }).then((res) => {
        this.list = res.data;
      });
    },
    handleClick(tab, event) {
      this.activeName = tab.name;
      this.getData();
    },
    toOpenInvoice(item) {
      this.$router.push({
        path: "/user/myInvoice/openInvoice",
        query: {
          orderNo: item.orderNo,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 30px;
  width: 950px;
  margin: 0 auto;
  img {
    width: 400px;
    height: 400px;
  }
  p {
    text-align: center;
    font-size: 16px;
    color: #666666;
  }
}
.my_content {
  padding: 30px;
}
.my_list {
  width: 100%;
  margin: 10px 0;
  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left {
      display: flex;
      flex-direction: column;
      .title {
        display: flex;
        align-items: center;
        img {
          width: 24px;
          height: 22px;
        }
        p {
          margin-left: 10px;
        }
      }
      span {
        margin-top: 20px;
      }
    }
  }
}
.line {
  border-bottom: 1px dashed #cccccc;
  margin: 15px 0;
}
.btn {
  display: block;
  width: 102px;
  height: 32px;
  background: #5586e1;
  border-radius: 19px;
  line-height: 32px;
  text-align: center;
  cursor: pointer;
}
</style>
<style scoped>
.my_content >>> .el-tabs__item {
  color: #999;
}
.my_content >>> .el-tabs__item.is-active {
  color: #5586e0;
}
.my_content >>> .el-tabs__active-bar {
  background-color: #5586e0;
  color: #fff;
}
</style>
